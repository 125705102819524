import React from "react";
import '../styles/ContactUs.css';

function ContactUs(){

    return (
        <section className="contact-us">
            <h2>Contact Us</h2>
            <p>
                If you require legal assistance or have any questions about our insurance law services, please contact us to schedule a consultation.</p>
                <p>We look forward to serving you and addressing your insurance legal needs.</p>
            <div>
                DopInsurance Legal Solutions<br />
                +43 1 234 5678<br />
                info@DopInsurance.com<br />
            </div>
        </section>
    );
};


export default ContactUs;
