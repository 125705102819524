import React from "react";
import '../styles/WhyChooseUs.css';
import insuranceFirmPic from "../styles/law_pic.jpeg";

function WhyChooseUs(){
    return(
        <section className="why-choose-us">
            <div className='content'>
                <div className="text">
                    <h2>Why Choose Us</h2>
                    <p>Insurance Law Specialists: Our team consists of experienced insurance law specialists with a track record of success in handling insurance-related cases.</p>
                    <p>Client-Centric Approach: We prioritize our clients' needs and provide personalized legal solutions to ensure the best outcomes in insurance matters.</p>
                    <p>Strategic Guidance: We offer strategic guidance and tailored legal strategies to navigate the complexities of insurance law effectively.</p>
                    <p>Transparent Communication: We believe in open and transparent communication, keeping our clients informed and involved throughout their insurance legal proceedings.</p>
                    <p>Commitment to Excellence: DopInsurance Legal Solutions upholds the highest standards of professionalism, ethics, and legal expertise in the insurance law domain.</p>
                </div>
                <div className="image-container">
                    <img src={insuranceFirmPic} alt="insurance firm pic" className="pic" />
                </div>
            </div>
        </section>
    );
};

export default WhyChooseUs;
