import React from "react";

function Service({serviceName}){
    return(
        <li>{serviceName}</li>

    );
};


export default Service