import React from 'react';
import "../styles/AboutUs.css";

function AboutUs() {
    return (
        <section className="about-us">
            <div className="content">
                <div className="text">
                    <h2>About Us</h2>
                    <p>Established in 1983, DopInsurance Legal Solutions has been serving clients with integrity and excellence in Vienna.</p>
                    <p>Our attorneys are highly skilled professionals with a deep understanding of insurance law and a commitment to achieving the best possible outcomes for our clients.</p>
                    <p>We take pride in our client-centered approach, ensuring that each client receives personalized attention and strategic legal solutions.</p>
                </div>
                <ul className="practice-areas">
                    <li>Insurance Coverage Disputes</li>
                    <li>Bad Faith Claims</li>
                    <li>Policy Interpretation</li>
                    <li>Insurance Litigation</li>
                    <li>Insurance Regulatory Compliance</li>
                    <li>Insurance Fraud Defense</li>
                </ul>
            </div>
        </section>
    );
}

export default AboutUs;
