import React from 'react';
import '../styles/Introduction.css';

function Introduction (){
    return (
        <section className="introduction"> 
            <div className="welcome">
                <h1>DopInsurance Legal Solutions </h1>
                <h2>Your Trusted Insurance Law Partner</h2>
            </div>
            <p>
                At DopInsurance Legal Solutions, we understand the complexities of insurance law and the importance of finding reliable legal counsel.</p>
            <p>With our team of experienced insurance law attorneys, we are dedicated to providing exceptional legal services tailored to meet your insurance needs.</p>
        </section>
    );
};

export default Introduction;