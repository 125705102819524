import React from 'react';
import '../styles/OurServices.css';
import Service from './Service';

function OurServices() {
    return (
        <section className="our-services">
            <div className="content">
                <div className="text">
                    <h2>Our Services</h2>
                    <p>DopInsurance Legal Solutions offers a comprehensive range of legal services specifically tailored to insurance matters for individuals, businesses, and organizations.</p>
                    <p>Whether you need assistance with insurance coverage disputes, policy interpretation, bad faith claims, or any other insurance-related issue, our team is here to guide you through every step of the legal process.</p>
                </div>
                <ul className="services-list">
                    <Service serviceName="Insurance Coverage Disputes"/>
                    <Service serviceName="Policy Interpretation"/>
                    <Service serviceName="Bad Faith Claims"/>
                    <Service serviceName="Insurance Litigation"/>
                    <Service serviceName="Insurance Regulatory Compliance"/>
                    <Service serviceName="Insurance Fraud Defense"/>
                </ul>
            </div>
        </section>
    );
}

export default OurServices;
