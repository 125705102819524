import React from 'react';
import Introduction from '../components/Introduction';
import AboutUs from '../components/AboutUs';
import OurServices from '../components/OurServices';
import WhyChooseUs from '../components/WhyChooseUs';
import ContactUs from '../components/ContactUs';
import logo from "../styles/law_firn_logo.png"
import "../styles/MainPage.css"


const MainPage = () => {
    return (
        <div className="home">
            <img src={logo} alt="Law Firm Logo" className="logo"/>
            <Introduction />
            <AboutUs />
            <OurServices />
            <WhyChooseUs />
            <ContactUs />
        </div>
    );
};

export default MainPage;